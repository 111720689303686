import image from "../../../../assets/images/logo/logo.png";

function Header() {
    return (
        <div className="header__background header__background--scroll">
            <div className="header__wrapper">
                <a href={image} className="header__logo">
                    <img src={image} alt="logo" />
                </a>
                <div className="header__right">
                    <nav className="header__nav">
                        <ul className="header__nav-list">
                            <li>
                                <a href="/" className="header__navlink">Services</a>
                            </li>
                            <li>
                                <a href="/" className="header__navlink">Download</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Header;