
const Modal = ({ isShowing, hide, progressTipSystem, progressTipLanguage }) => {
    return (
        isShowing ? (
            <div className="progress-tips">
                <ul>
                    {
                        progressTipSystem === true ? (
                            <li>
                                <div className="percent">- 10%</div>
                                <div className="description">
                                    <h2>System time different</h2>
                                    <p>The time set in your system differs from your IP addresses time zone. You are possibly trying to hide your current location by anonymity means.</p>
                                </div>
                            </li>
                        ) : null
                    }
                    {
                        progressTipLanguage === true ? (
                            <li>
                                <div className="percent">- 10%</div>
                                <div className="description">
                                    <h2>Languages different</h2>
                                    <p>The language set in your system and/or browser differs from the language of your IP address country. You are possibly trying to hide yourself by anonymity means.</p>
                                </div>
                            </li>
                        ) : null
                    }
                </ul>
            </div>
        ) : null
    )
}

export default Modal