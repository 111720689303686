import { ImageHsts, ImageWebg, ImageCanvas, ImagePlugin, ImageMedia, ImageReact, ImageFont } from '../../assets/images/index';
import {
  Address, Browser, Dns, Isp, Language, Os, TimeZone, Webrtc
} from '../../assets/images/index';
import { useEffect, useState } from 'react';
import getDetect from '../../assets/js/main'
import { jsWgl, jsCanvas, jsAudio, jsRects, jsFonts, jsPlugins } from '../../assets/js/webgl'
import axios from 'axios';
import ProgressBar from '../../components/Progressbar/Progressbar';

function Home() {

  useEffect(() => {
    getDetect.screen()
    getDetect.plugin()
    getDetect.navigator()
    getDetect.language()
    getDetect.timeZone()
    jsWgl()
    jsCanvas()
    jsAudio()
    jsRects()
    jsFonts()
    jsPlugins()
    if (navigator.cookieEnabled) {
      getDetect.setStatus("#cookies-status")
    }
    if (navigator.javaEnabled()) {
      getDetect.setStatus("#java-status");
    }
  }, [])

  // handle get ip 
  const [dataIp, setDataIp] = useState([]);
  const [data, setData] = useState([])
  const getData = async () => {
    const res = await axios.get("https://api.multibrowser.io/v1/iplocation")
    setDataIp(res.data)
  }

  useEffect(() => {
    getData()
    axios.get(`https://v2-api.multibrowser.io/v1/geoip?ip=` + dataIp)
      .then(res => {
        const data = res.data
        // console.log(data)
        setData(data)
      })
      .catch(error => console.log(error))
  }, []);

  useEffect(() => {
    if (data.ip) {
      document
        .querySelector(".card__data_webrtc .webrtc-status-icon")
        .classList.remove("disabled");
      document.querySelector(".card__data_webrtc .cont").textContent = data.ip;
    } else {
      document
        .querySelector(".card__data_webrtc .webrtc-status-icon")
        .classList.add("disabled");
      document.querySelector(".card__data_webrtc .cont").textContent = "";
    }
  }, [data])

  let nAgt = navigator.userAgent
  let browserName = navigator.appName
  let fullVersion = "" + parseFloat(navigator.appVersion)
  let platform = 'null'
  let language = navigator.language
  let nameOffset, verOffset, ix;

  // Check platform userAgentData
  if (navigator.userAgentData) {
    platform = navigator.userAgentData.platform
  } else {
    if ((nAgt.indexOf("Windows")) !== -1) {
      platform = "Windows"
    }
    else if ((nAgt.indexOf("Mac")) !== -1) {
      platform = "Mac"
    }
    else if ((nAgt.indexOf("Linux")) !== -1) {
      platform = "Linux"
    }
  }

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) !== -1)
    fullVersion = fullVersion.substring(0, ix);

  return (
    <div id="main">
      <section className="section_main section_user-ip section">
        <div className="section__wrapper">
          <div className="section__content">
            <div className="ip-show">
              <div className="box">
                <div className="introduce">
                  <div className="title">
                    <span className="me-4">My IP</span>
                  </div>
                  <div className="ip-country">
                    <span>{data ? data.country : 'null'}</span>
                  </div>
                </div>
                <div className="ip">
                  <h1>{data ? data.ip : 'null'}</h1>
                </div>
                <div className="safe-container">
                  <a href="https://multibrowser.io/" className="safe">
                    <span>Stay safe online</span>
                    <div className="switch-box">
                      <div className="switch-button"></div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="location-info container_main-ip-info">
              <div className="row main-ip-info__ip-data">
                <div className="col-md-6 col-sm-12">
                  <div className="ip-data__row ip-info">
                    <div className="ip-data__col ip-data__col_param">
                      <div>ISP:</div>
                    </div>
                    <div className="ip-data__col ip-data__col_icon">
                      <div className="icon icon_sm icon_provider">
                        <img src={Isp} alt="" />
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_value">
                      <div>
                        <span>{data ? data.isp : 'null'}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ip-data__row">
                    <div className="ip-data__col ip-data__col_param">
                      <div>
                        OS:
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_icon">
                      <div className="icon icon_sm icon_hostname">
                        <img src={Os} alt="" />
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_value">
                      <div>
                        <span>{platform}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ip-data__row">
                    <div className="ip-data__col ip-data__col_param">
                      <div>Browser:</div>
                    </div>
                    <div className="ip-data__col ip-data__col_icon">
                      <div className="icon icon_sm icon_hostname">
                        <img src={Browser} alt="" />
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_value">
                      <div>
                        <span>{browserName + ' ' + fullVersion}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ip-data__row">
                    <div className="ip-data__col ip-data__col_param">
                      <div>
                        Timezone:
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_icon">
                      <div className="icon icon_sm icon_hostname">
                        <img src={TimeZone} alt="" />
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_value">
                      <div>
                        <span>{data ? data.time_zone : 'null'}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  {/* <div className="ip-data__row">
                    <div className="ip-data__col ip-data__col_param">
                      <div>
                        DNS
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_icon">
                      <div className="icon icon_sm icon_dns">
                        <img src={Dns} alt="" />
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_value">
                      <div className="dns_br" data-once="1">
                        <div className="skel">
                          <span className="ico-holder ico-dns-net"> </span>
                          <span className="cont dns_br_ip max_ip"></span>
                          <span className="country-holder main-dns">
                            <span className="ico-holder no-back dns_br_flag"> </span>
                            <span className="cont dns_br_country"></span>
                          </span>
                        </div>
                        <div className="result"><div className="dns_country_tw">
                          <span className="ico-holder ico-dns-net"></span>
                          <span className="cont dns_br_ip max_ip"><span className="dashed-thin">172.217.42.15</span><span><sup>2</sup></span></span>
                          <span className="country-holder main-dns">
                            <span className="ico-holder no-back dns_br_flag"></span>
                            <span className="cont dns_br_country">Taiwan</span>
                          </span>
                        </div></div>
                      </div>
                    </div>
                  </div> */}
                  <div className="ip-data__row">
                    <div className="ip-data__col ip-data__col_param">
                      <div>
                        WebRTC:
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_icon">
                      <div className="icon icon_sm icon_hostname">
                        <img src={Webrtc} alt="" />
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_value">
                      <div>
                        <span>{data ? data.ip : 'null'}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ip-data__row">
                    <div className="ip-data__col ip-data__col_param">
                      <div>
                        Address:
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_icon">
                      <div className="icon icon_sm icon_hostname">
                        <img src={Address} alt="" />
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_value">
                      <div>
                        <span>{data && data.city !== undefined ? (data.city + '|' + data.lat + data.lng) : ''}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ip-data__row">
                    <div className="ip-data__col ip-data__col_param">
                      <div>
                        Language:
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_icon">
                      <div className="icon icon_sm icon_hostname">
                        <img src={Language} alt="" />
                      </div>
                    </div>
                    <div className="ip-data__col ip-data__col_value">
                      <div>
                        <span>{language ? language : ""}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ProgressBar
              data={data}
            />
          </div>
        </div>
      </section>
      <div className="finger-prints">
        <h2 className="finger-title text-center pb-4">Browser fingerprint online detection</h2>
        <div className="row">
          {/* <div className="col-lg-4">
            <div className="d-flex list-items">
              <div className="left">
                <img src={ImageHsts} alt="" />
              </div>
              <div className="right">
                <h5 className="title">HSTS</h5>
                <p className="prints-intro">HSTS Protocol</p>
                <span></span>
              </div>
            </div>
          </div> */}
          <div className="col-lg-4">
            <div className="d-flex list-items">
              <div className="left">
                <img src={ImageWebg} alt="" />
              </div>
              <div className="right">
                <h5 className="title">WEBGL</h5>
                <p className="prints-intro">Graphics card and driver fingerprint</p>
                <span id="q-js-wgl"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex list-items">
              <div className="left">
                <img src={ImageCanvas} alt="" />
              </div>
              <div className="right">
                <h5 className="title">CANVAS</h5>
                <p className="prints-intro">2D images and animations to draw signature fingerprints</p>
                <span id="q-js-canvas"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex list-items">
              <div className="left">
                <img src={ImagePlugin} alt="" />
              </div>
              <div className="right">
                <h5 className="title">PLUGINS</h5>
                <p className="prints-intro">Fingerprint of browser pre-installed plugin feature</p>
                <span id="q-js-plugins"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex list-items">
              <div className="left">
                <img src={ImageMedia} alt="" />
              </div>
              <div className="right">
                <h5 className="title">AUDIO</h5>
                <p className="prints-intro">Sound settings and fingerprint hardware feature</p>
                <span id="q-js-audio"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex list-items">
              <div className="left">
                <img src={ImageReact} alt="" />
              </div>
              <div className="right">
                <h5 className="title">RECTS</h5>
                <p className="prints-intro">ClientRects fingerprint</p>
                <span id='q-js-rects'></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex list-items">
              <div className="left">
                <img src={ImageFont} alt="" />
              </div>
              <div className="right">
                <h5 className="title">FONTS</h5>
                <p className="prints-intro">Fonts and Features Fingerprint Input Device</p>
                <span id="q-js-fonts"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-cus">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-lg-12">
                <div className="ip-check__card">
                  <h4 className="card__title">Browser</h4>
                  <div className="card__data">
                    <div className="card__row">
                      <div className="card__col card__col_param">Browser:</div>
                      <div className="card__col card__col_value" id="browser">{browserName}</div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">
                        Browser version:
                      </div>
                      <div className="card__col card__col_value" id="vBrowser">{fullVersion}</div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">Platform:</div>
                      <div className="card__col card__col_value" id="platform">{navigator.userAgentData ? navigator.userAgentData.platform : ""}</div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">JavaScript:</div>
                      <div className="card__col card__col_value" id="javaScript">{navigator.userAgent ? navigator.userAgent : ""}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="ip-check__card">
                  <h4 className="card__title">Navigator</h4>
                  <div className="card__data" id="browser-navigator">
                    <div className="card__row">
                      <div className="card__col card__col_param">VendorSub:</div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">ProductSub:</div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">Google Inc:</div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">
                        MaxTouchPoints:
                      </div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">
                        HardwareConcurrency:
                      </div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">DeviceMemory:</div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">
                        CookieEnabled:
                      </div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">AppCodeName:</div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">AppName:</div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">AppVersion:</div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">Platform:</div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">Product:</div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">UserAgent:</div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">Language:</div>
                      <div className="card__col card__col_value"></div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">OnLine:</div>
                      <div className="card__col card__col_value"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="ip-check__card">
                  <h4 className="card__title">EXTENDED</h4>
                  <div className="card__data">
                    <div className="card__row">
                      <div className="card__col card__col_param">WebGL:</div>
                      <div className="card__col card__col_value">
                        <span id="q-js-wgl"></span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">Canvas:</div>
                      <div className="card__col card__col_value">
                        <span id="q-js-canvas"></span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">Audio:</div>
                      <div className="card__col card__col_value">
                        <span id="q-js-audio"></span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">Fonts:</div>
                      <div className="card__col card__col_value">
                        <span id="q-js-fonts"></span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">Client Rects:</div>
                      <div className="card__col card__col_value">
                        <span id="q-js-rects"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-12">
                <div className="ip-check__card">
                  <h4 className="card__title">Language</h4>
                  <div className="card__data" id="language"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-lg-12">
                <div className="ip-check__card screen-info">
                  <h4 className="card__title">Screen</h4>
                  <div className="card__data" id="screen">
                    <div className="card__row">
                      <div className="card__col card__col_param">ColorDepth:</div>
                      <div className="card__col card__col_value">
                        <span id="colorDepth">{window.screen ? window.screen.colorDepth : ""}</span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">PixelDepth:</div>
                      <div className="card__col card__col_value">
                        <span id="pixelDepth">{window.screen ? window.screen.pixelDepth : ""}</span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">Height:</div>
                      <div className="card__col card__col_value">
                        <span id="height">{window.screen ? window.screen.height : ""}</span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">Width:</div>
                      <div className="card__col card__col_value">
                        <span id="width">{window.screen ? window.screen.width : ""}</span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">AvailHeight:</div>
                      <div className="card__col card__col_value">
                        <span id="availHeight">{window.screen ? window.screen.availHeight : ""}</span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">AvailWidth:</div>
                      <div className="card__col card__col_value">
                        <span id="availWidth">{window.screen ? window.screen.availWidth : ""}</span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">AvailTop:</div>
                      <div className="card__col card__col_value">
                        <span id="availTop">{window.screen ? window.screen.availTop : ""}</span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">AvailLeft:</div>
                      <div className="card__col card__col_value">
                        <span id="availLeft">{window.screen ? window.screen.availLeft : ""}</span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">Window size:</div>
                      <div className="card__col card__col_value">
                        <span id="screenSize">{getDetect.screen()}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="ip-check__card">
                  <h4 className="card__title">Plugin</h4>
                  <div className="card__data card__data_with-overflow" id="plugins"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="ip-check__card">
                  <h4 className="card__title">Time</h4>
                  <div className="card__data">
                    <div className="card__row">
                      <div className="card__col card__col_param">Zone:</div>
                      <div className="card__col card__col_value">
                        <span id="zone">{data ? data.time_zone : ''}</span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">Local:</div>
                      <div className="card__col card__col_value">
                        <span id="local-time">{data ? data.local_time : ''}</span>
                      </div>
                    </div>
                    <div className="card__row">
                      <div className="card__col card__col_param">System:</div>
                      <div className="card__col card__col_value">
                        <span id="system-time"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="ip-check__card">
                  <h4 className="card__title">WebRTC</h4>
                  <div className="card__data card__data_webrtc">
                    <div className="enabled-status__wrapper">
                      <span className="enabled-status webrtc-status-icon disabled"></span>
                      <span className="cont"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="ip-check__card">
                  <h4 className="card__title">JavaScript</h4>
                  <div className="card__data" id="javascript-status">
                    <div className="enabled-status__wrapper" id="js-status">
                      <noscript>
                        <span className="enabled-status js-status-icon disabled"></span>
                        <span className="cont js-status">Disabled</span>
                      </noscript>
                      <span className="enabled-status js-status-icon enabled" ></span>
                      <span className="cont js-status">Enabled</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="ip-check__card">
                  <h4 className="card__title">Flash</h4>
                  <div className="card__data card__data_webrtc">
                    <div className="enabled-status__wrapper" id="flash-status">
                      <span className="enabled-status java-status-icon disabled"></span>
                      <span className="cont flash-status">Disabled</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="ip-check__card">
                  <h4 className="card__title">ActiveX</h4>
                  <div className="card__data card__data_webrtc">
                    <div className="enabled-status__wrapper" id="activex-status">
                      <span className="enabled-status java-status-icon disabled"></span>
                      <span className="cont activex-status">Disabled</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="ip-check__card">
                  <h4 className="card__title">Java</h4>
                  <div className="card__data card__data_webrtc">
                    <div className="enabled-status__wrapper" id="java-status">
                      <span className="enabled-status java-status-icon disabled"></span>
                      <span className="cont">Disabled</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="ip-check__card">
                  <h4 className="card__title">Cookies</h4>
                  <div className="card__data card__data_webrtc">
                    <div className="enabled-status__wrapper" id="cookies-status">
                      <span className="enabled-status java-status-icon disabled"></span>
                      <span className="cont">Disabled</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
