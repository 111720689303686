import image from "../../../../assets/images/logo/logo.png";

function Footer() {
    return (
        <div className="footer-init">
            <div className="footer">
                <img src={image} alt="" />
                <p className="intro mt-3">ipGet thích hợp để kiểm tra máy chủ Proxy và Socks, DNS của địa chỉ IP proxy, phiên bản hệ điều hành, dấu vân tay của trình duyệt, rò rỉ WebRTC, vị trí, ngôn ngữ và các truy vấn ngụy trang khác. Chức năng cốt lõi và ưu điểm chính của dịch vụ này là kiểm tra tương tác thông qua Java, Flash và WebRTC, có thể phát hiện ra các cài đặt và điểm yếu thực sự của hệ thống, đồng thời ngăn các tài nguyên khác học thông tin mà bạn không sẵn sàng chia sẻ.</p>
                <p className="copyright"> Copyright © multibrowser </p>
            </div>
            {/* <div className="footer-right">
                <h2>Link</h2>
                <ul>
                    <li><a href="/">Migratory bird fingerprint browser</a></li>
                    <li><a href="/">Super VPS Manager</a></li>
                </ul>
            </div> */}
        </div>
    )

}

export default Footer