import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { publicRoutes } from './routes/route';
import DefaultLayout from './components/Layout/DefaultLayout';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/app.css';
import '../src/assets/js/cryptojs'

function App() {
  return (
    <Router>
      <Routes>
        {publicRoutes.map((route, index) => {
          const Layout = route.layout || DefaultLayout;
          const Page = route.component;
          return <Route key={index} path={route.path} element={
            <Layout>
              <Page />
            </Layout>}
          />;
        })}
      </Routes>
    </Router>
  );
}

export default App;
