// Pages
import Home from '../pages/Home/home';
import Services from '../pages/Services';
import MyIP from '../pages/MyIP';

// Public routes
const publicRoutes = [
    { path: '/', component: Home },
    { path: '/services', component: Services },
    { path: '/myip', component: MyIP }
];

const privateRoutes = [

];

export { publicRoutes, privateRoutes };
