const getDetect = {
    screen: () => {
        let winWidth = "?";
        let winHeight = "?";
        try {
            if (document) {
                winWidth = document.body.offsetWidth;
                winHeight = document.body.offsetHeight;
            } else if (document.layers) {
                winWidth = document.body.width;
                winHeight = document.body.height;
            } else if (document.body.clientWidth !== null) {
                winWidth = document.body.clientWidth;
                winHeight = document.body.clientHeight;
            }
        } catch (e) {
            console.error(e);
        }
        return `${winWidth}x${winHeight} (${window.screen.width}x${window.screen.height})`;
    },
    plugin: () => {
        if (navigator.plugins) {
            const childPlugDivs = document.querySelector("#plugins");
            const pluginsValues = Object.values(navigator.plugins);

            if (!pluginsValues.length) {
                childPlugDivs.textContent = "Plugins missing";
            }

            pluginsValues.forEach((elem) => {
                const pluginsDiv = document.createElement("div");
                const pluginsParam = document.createElement("div");
                const pluginsValue = document.createElement("div");

                pluginsDiv.classList.add("card__row");
                pluginsParam.classList.add("card__col", "card__col_param");
                pluginsValue.classList.add("card__col", "card__col_value");

                pluginsDiv.appendChild(pluginsParam);
                pluginsDiv.appendChild(pluginsValue);

                pluginsParam.textContent = elem.name;
                pluginsValue.textContent = elem.filename;

                childPlugDivs.appendChild(pluginsDiv);
            });
        }
    },
    navigator: () => {
        if (navigator) {
            const navFieldsArr = [
                "vendorSub",
                "productSub",
                "vendor",
                "maxTouchPoints",
                "hardwareConcurrency",
                "deviceMemory",
                "cookieEnabled",
                "appCodeName",
                "appName",
                "appVersion",
                "platform",
                "product",
                "userAgent",
                "language",
                "onLine",
            ];

            const childNavDivs = document.querySelectorAll("#browser-navigator > div");
            navFieldsArr.forEach((elem, index) => {
                if (navigator[elem] || Number.isInteger(navigator[elem])) {
                    childNavDivs[index].children[1].textContent = navigator[elem];
                }
            });

            // if (navigator.cookieEnabled) {
            //     setStatus("#cookies-status");
            // }

            // if (navigator.javaEnabled()) {
            //     setStatus("#java-status");
            // }
        }
        document.querySelectorAll("#javascript-status span").forEach((node) => {
            node.style.display = "block";
        });
    },
    setStatus: (selector) => {
        document
            .querySelector(`${selector} > .java-status-icon`)
            .classList.remove("disabled");
        document.querySelector(`${selector} > .cont`).textContent = "Enabled";

        let activeX = false;
        let flash = false;

        try {
            flash = new window.ActiveXObject("ShockwaveFlash.ShockwaveFlash");
            activeX = true;
        } catch (e) {
            const { mimeTypes } = navigator;
            if (
                mimeTypes &&
                mimeTypes["application/x-shockwave-flash"] &&
                navigator.mimeTypes["application/x-shockwave-flash"].enabledPlugin
            ) {
                flash = true;
            }
        }
    },
    language: () => {
        if (
            navigator &&
            navigator.languages &&
            Array.isArray(navigator.languages)
        ) {
            const { languages } = navigator;
            let resultLanguages = [];

            languages.forEach((item) => {
                const splittedItem = item.split("-");
                resultLanguages.push(splittedItem.pop().toUpperCase());
            });

            resultLanguages = [...new Set(resultLanguages)];

            resultLanguages.forEach((item) => {
                const span = document.createElement("span");
                span.className = "lang";
                span.textContent = item;
                document.getElementById("language").append(span);
            });
        }
    },
    timeZone: () => {
        document.getElementById("system-time").textContent = new Date();
    }
}

export default getDetect;
