import { useEffect, useState } from 'react'
import { ImageHacker } from '../../assets/images/index'
import Modal from '../Modal/Modal'
import useModal from '../../assets/js/useModal';
import { convertLanguages } from '../Convert/Convert_object';

const ProgressBar = (data) => {
    const { isShowing, toggle } = useModal()
    const [filled, setFilled] = useState(0)
    const [percent, setPercent] = useState()
    const [progressTipSystem, setProgressTipSystem] = useState(false)
    const [progressTipLanguage, setProgressTipLanguage] = useState(false)
    const [timeZoneLocal, setTimeZoneLocal] = useState()

    useEffect(() => {
        let languageLocal
        if (data.data.language) {
            languageLocal = JSON.stringify(convertLanguages(data.data.language))
        }
        let languageApi
        if (navigator && navigator.languages && Array.isArray(navigator.languages)) {
            const { languages } = navigator;
            languageApi = JSON.stringify(languages)
        }
        const newDate = new Date().toTimeString().slice(9, 17)
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        setTimeZoneLocal(timeZone.concat(", ", newDate))
        let totalValue = 100, passValue = 0;
        setPercent(totalValue)
        if (timeZoneLocal !== data.data.time_zone) {
            passValue = totalValue - 10
            setProgressTipSystem(true)
            setPercent(passValue)
        }
        if (languageLocal && languageLocal !== languageApi) {
            passValue -= 10
            setProgressTipLanguage(true)
            setPercent(passValue)
        }
    }, [data])

    useEffect(() => {
        if (filled < 100) {
            setTimeout(() => setFilled(prev => prev += 2), 50)
        }
    }, [filled])

    return (
        <>
            <div className="ip-progress">
                <div className="bg">
                    <div className="bar">
                        <div className="bar-rate" style={{
                            width: `${filled}%`,
                            backgroundColor: "rgb(66, 185, 131)",
                            transition: "width 1s",
                        }}></div>
                        <div className="bar-show">
                            <div className="bar-show-init">
                                <div className="icon">
                                    <img src={ImageHacker} alt="" />
                                </div>
                                <div className="tips">
                                    <span>You are {percent}% safe</span>
                                </div>
                                <button className="button" onClick={toggle}>Expanded details</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isShowing={isShowing}
                hide={toggle}
                progressTipSystem={progressTipSystem}
                progressTipLanguage={progressTipLanguage}
            />
        </>
    )
}

export default ProgressBar