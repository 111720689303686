import Header from "./Header/header";
import Footer from './Footer/footer'

function DefaultLayout({ children }, props) {
    return (
        <div id="wrapper">
            <header className="header">
                <Header />
            </header>
            <div id="content">
                {children}
            </div>
            <footer className="footer">
                <Footer />
            </footer>
        </div>
    );
}

export default DefaultLayout;